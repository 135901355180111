import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-components';
import PrivateRoute from './containers/Security/PrivateRoute';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import  client from './remote/apollo';

const loading = () => <div className="animated fadeIn pt-3 text-center">Загрузка...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(()  => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Printer = React.lazy(() => import('./views/Pages/Printer'));

class App extends Component {

  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      auth: false,
    };
    console.log("get auth state from server")
  }


  render() {

//	const isAuth = () => (this.state.auth);
	const setAuth = (e) => (this.setState({auth: e}));

    const defaultProps = {
	  isAuth: () => (this.state.auth),
	  setAuth: (e) => (this.setState({auth: e}))
    };

    return (
    <ApolloProvider client={client}>
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login  {...defaultProps} {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/print" name="Printer" render={props => <Printer {...props}/>} />
              <PrivateRoute authed={this.state.auth} {...defaultProps} path='/' component={DefaultLayout} />

//              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    </ApolloProvider>
    );
  }
}

export default App;
