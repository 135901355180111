import React from "react";
import configData from "./config/configData";

class Config {
  getAppObjescts() {
    return configData.appobjects;
  }
  getAppObj(name) {
    return this.getAppObjescts().find(row => row.id.toString() === name);
  }
  getRemoteAPIUrl() {
    return configData.remoteAPIUrl;
  }
  isLocalData() {
    return configData.noRemote;
  }
  getUploadedImagesBaseUrl() {
    return configData.exportDir;
  }
  getAppObjList(appobjName) {
    return this.getAppObjListComponent(this.getAppObj(appobjName))
  }

  getAppObjListComponent(appobj) {
    return this.getComponent(appobj.list.path)
  }

  getAppObjEditComponent(appobj) {
    return this.getComponent(appobj.edit.path)
  }

  getComponent(path) {
    return React.lazy(() => import("." + path))
  }

  getNavAppRoutes() {
    var res = [];
    this.getAppObjescts().map((appobj, k) => {
      res.push({
        path: appobj.routePath + "/:id",
        exact: true,
        name: appobj.edit.title,
        component: this.getAppObjEditComponent(appobj)
      });
      res.push({
        path: appobj.routePath,
        exact: true,
        name: appobj.list.title,
        component: this.getAppObjListComponent(appobj)
      });
    });
    return res;
  }



  getPersistValue(name) {
    const v = sessionStorage.getItem(name)
    //console.log("config getPersistValue=" + name +"=")
    //console.log(v)
    return v != null ? JSON.parse(v) : v
  }

  setPersistValue(name, value) {
    //console.log("config setPersistValue=" + name +"=")
    //console.log(value)

    sessionStorage.setItem(name, value != null ? JSON.stringify(value) : value)
  }

  // -----
  // ----- security
  // -----

  calcAccess(access, action) {//access - array of roles accesses, join by conjunction
    console.log(access)
    console.log(action)
    console.log(access.reduce((acc, itm) => {return acc||itm[action]}, false))
    return access.reduce((acc, itm) => acc||itm[action], false)
  }

  getObjAccessByUserRole(appObjName) {
    const user = config.getPersistValue(CFG_KEY_SESSION)                      // user.roles = [id:1,code:'teacher',title:'']
    const access = this.getAppObj(appObjName).access
    return {access:access ? access.filter(row => user&&user.roles&&user.roles.find(r=>r.code===row.role)):[],user}
  }

  checkAccessPermitted(appObjName, action) {
    console.log("appObjName=")
    console.log(appObjName)
    const {access,user} = this.getObjAccessByUserRole(appObjName)
    console.log(user)
    return (user && user.is_superuser) || (access && this.calcAccess(access,action));
  }

  getMenuVisibilty(appObjName, action) {
    return this.checkAccessPermitted(appObjName, action ? action : "rubricator")
  }

}

export const config = new Config();

export const CFG_KEY_SESSION = "CFG_KEY_SESSION"
export const CFG_KEY_SESSION_CLIENT = "CFG_KEY_SESSION_CLIENT"
export const CFG_KEY_FILTER = "CFG_KEY_SESSION_DASHBOARD_FILTER"
