const configData = {
  dictDownloadRowLimit: 10000, // limit rows to download
  noRemote: false, // no API server, simulate data with local test data
  remoteAPIUrl: "/", // url for backend API Server
  exportDir: "/export/images/",
  appobjects: [
    {
      id: "user",
      remoteId: "DictUser",
      routePath: "/users",
      edit: {
        title: "Информация о пользователе",
        path: "/views/dicts/DictUser/UserEdit"
      },
      list: { title: "Пользователи", path: "/views/dicts/UserDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
        {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "userlinked",
      remoteId: "DictUserLinked",
      routePath: "/dict/userlinked",
      edit: {
        title: "Связанный пользователь",
        path: "/views/dicts/DictUserLinked/UserLinkedEdit"
      },
      list: { title: "Связанные пользователи", path: "/views/dicts/UserLinkedDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
        {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "userlinkedtype",
      remoteId: "DictUserLinkedType",
      routePath: "/dict/userlinkedtype",
      edit: {
        title: "Тип связи пользователя",
        path: "/views/dicts/DictUserLinkedType/UserLinkedTypeEdit"
      },
      list: { title: "Тип связи пользователя", path: "/views/dicts/UserLinkedTypeDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
        {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "usermsg",
      remoteId: "DictUserMsg",
      routePath: "/users",
      edit: {
        title: "Сообщения пользователя",
        path: "/views/dicts/DictUser/UserEdit"
      },
      list: { title: "Пользователи", path: "/views/dicts/UserDict" }
    },
    {
      id: "role",
      remoteId: "DictRole",
      routePath: "/roles",
      edit: { title: "Роль", path: "/views/dicts/DictRole/RoleEdit" },
      list: { title: "Роли пользователей", path: "/views/dicts/RoleDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
        {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "city",
      remoteId: "DictCity",
      routePath: "/dicts/city",
      edit: { title: "Город", path: "/views/dicts/DictCity/CityEdit" },
      list: { title: "Города", path: "/views/dicts/CityDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:false, edit:false, print:true},
         {role:"schadmin", rubricator:false,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evodiary",
      remoteId: "DictEvoDiary",
      routePath: "/dicts/evodiary",
      edit: { title: "Дневник самооценки", path: "/views/dicts/DictEvoDiary/EvoDiaryEdit" },
      list: { title: "Дневники самооценки", path: "/views/dicts/EvoDiaryDict" },
      access:
        [{role:"teacher", rubricator:true,create: true, read:true, edit:true, print:true},
         {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]

    },
    {
      id: "evotest",
      remoteId: "DictEvoTest",
      routePath: "/dicts/evotest",
      edit: { title: "Самостоятельная работа", path: "/views/dicts/DictEvoTest/EvoTestEdit" },
      list: { title: "Самостоятельные работы", path: "/views/dicts/EvoTestDict" },
      access:
        [{role:"teacher", rubricator:true,create: true, read:true, edit:true, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evotesttask",
      remoteId: "DictEvoTestTask",
      routePath: "/dicts/evotesttask",
      edit: { title: "Задание", path: "/views/dicts/DictEvoTestTask/EvoTestTaskEdit" },
      list: { title: "Задания", path: "/views/dicts/EvoTestTaskDict" },
      access:
        [{role:"teacher", rubricator:true,create: true, read:true, edit:true, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evotestassignment",
      remoteId: "DictEvoTestAssignment",
      routePath: "/dicts/evotestassignment",
      edit: { title: "Назначение", path: "/views/dicts/DictEvoTestAssignment/EvoTestAssignmentEdit" },
      list: { title: "Назначения", path: "/views/dicts/EvoTestAssignmentDict" },
      access:
        [{role:"teacher", rubricator:true,create: true, read:true, edit:true, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evosubject",
      remoteId: "DictEvoSubject",
      routePath: "/dicts/evosubject",
      edit: { title: "Предмет", path: "/views/dicts/DictEvoSubject/EvoSubjectEdit" },
      list: { title: "Предметы", path: "/views/dicts/EvoSubjectDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evodiscrepancyreason",
      remoteId: "DictEvoDiscrepancyReason",
      routePath: "/dicts/evodiscrepancyreason",
      edit: { title: "Причина несовпадения результатов", path: "/views/dicts/DictEvoDiscrepancyReason/EvoDiscrepancyReasonEdit" },
      list: { title: "Причины несовпадения результатов", path: "/views/dicts/EvoDiscrepancyReasonDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:false},
          {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evogoalsetting",
      remoteId: "DictEvoGoalSetting",
      routePath: "/dicts/evogoalsetting",
      edit: { title: "Постановка целей", path: "/views/dicts/DictEvoGoalSetting/EvoGoalSettingEdit" },
      list: { title: "Постановки целей", path: "/views/dicts/EvoGoalSettingDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evograde",
      remoteId: "DictEvoGrade",
      routePath: "/dicts/evograde",
      edit: { title: "Отметка", path: "/views/dicts/DictEvoGrade/EvoGradeEdit" },
      list: { title: "Отметки", path: "/views/dicts/EvoGradeDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evocomplexitylevel",
      remoteId: "DictEvoComplexityLevel",
      routePath: "/dicts/evocomplexitylevel",
      edit: { title: "Уровень сложности", path: "/views/dicts/DictEvoComplexityLevel/EvoComplexityLevelEdit" },
      list: { title: "Уровни сложности", path: "/views/dicts/EvoComplexityLevelDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evoschooltype",
      remoteId: "DictEvoSchoolType",
      routePath: "/dicts/evoschooltype",
      edit: { title: "Тип школы", path: "/views/dicts/DictEvoSchoolType/EvoSchoolTypeEdit" },
      list: { title: "Типы школ", path: "/views/dicts/EvoSchoolTypeDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:false,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evoschool",
      remoteId: "DictEvoSchool",
      routePath: "/dicts/evoschool",
      edit: { title: "Школа", path: "/views/dicts/DictEvoSchool/EvoSchoolEdit" },
      list: { title: "Школы", path: "/views/dicts/EvoSchoolDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:false,create: false, read:true, edit:false, print:true}]
    },
    {
      id: "evoschoolclass",
      remoteId: "DictEvoSchoolClass",
      routePath: "/dicts/evoschoolclass",
      edit: { title: "Класс", path: "/views/dicts/DictEvoSchoolClass/EvoSchoolClassEdit" },
      list: { title: "Классы", path: "/views/dicts/EvoSchoolClassDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evostudent",
      remoteId: "DictEvoStudent",
      routePath: "/dicts/evostudent",
      edit: { title: "Ученик", path: "/views/dicts/DictEvoStudent/EvoStudentEdit" },
      list: { title: "Ученики", path: "/views/dicts/EvoStudentDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true, upload: true}]
    },
    {
      id: "evoteacher",
      remoteId: "DictEvoTeacher",
      routePath: "/dicts/evoteacher",
      edit: { title: "Учитель", path: "/views/dicts/DictEvoTeacher/EvoTeacherEdit" },
      list: { title: "Учителя", path: "/views/dicts/EvoTeacherDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true, upload: true}]
    },
    {
      id: "evoquarter",
      remoteId: "DictEvoQuarter",
      routePath: "/dicts/evoquarter",
      edit: { title: "Четверть", path: "/views/dicts/DictEvoQuarter/EvoQuarterEdit" },
      list: { title: "Четверти", path: "/views/dicts/EvoQuarterDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "evoadequacylimit",
      remoteId: "DictEvoAdequacyLimit",
      routePath: "/dicts/evoadequacylimit",
      edit: { title: "Предел адекватности", path: "/views/dicts/DictEvoAdequacyLimit/EvoAdequacyLimitEdit" },
      list: { title: "Пределы адекватности", path: "/views/dicts/EvoAdequacyLimitDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:true, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: true, read:true, edit:true, print:true}]
    },
    {
      id: "bank",
      remoteId: "DictBank",
      routePath: "/dicts/banks/bank",
      edit: { title: "Банк", path: "/views/dicts/DictBank/BankEdit" },
      list: { title: "Банки", path: "/views/dicts/BankDict" }
    },
    {
      id: "mcc",
      remoteId: "DictMcc",
      routePath: "/dicts/mcc",
      edit: { title: "Код МСС", path: "/views/dicts/DictMcc/MccEdit" },
      list: { title: "Коды МСС", path: "/views/dicts/MccDict" }
    },
    {
      id: "banktarif",
      remoteId: "DictBankTarif",
      routePath: "/dicts/banks/tarif/tarif",
      edit: { title: "Тариф", path: "/views/dicts/DictBankTarif/BankTarifEdit" },
      list: { title: "Тарифы", path: "/views/dicts/BankTarifDict" }
    },
    {
      id: "banktarifbet",
      remoteId: "DictBankTarifBet",
      routePath: "/dicts/banks/tarif/bet",
      edit: { title: "Тарифная ставка", path: "/views/dicts/DictBankTarifBet/BankTarifBetEdit" },
      list: { title: "Тарифные ставки", path: "/views/dicts/BankTarifBetDict" }
    },
    {
      id: "client",
      remoteId: "DictClient",
      routePath: "/dicts/clients/client",
      edit: { title: "Предприятие", path: "/views/dicts/DictClient/ClientEdit" },
      list: { title: "Предприятия", path: "/views/dicts/ClientDict" }
    },
    {
      id: "clientAll",
      remoteId: "DashboardFilter",
      selectAction: "dasboard_filter_get_clients",
      routePath: "/dicts/clients/clientall",
      edit: { title: "Предприятие", path: "/views/dicts/DictClient/ClientEdit" },
      list: { title: "Клиенты", path: "/views/dicts/ClientAll" }
    },
    {
      id: "clientgroup",
      remoteId: "DictClientGroup",
      routePath: "/dicts/clients/group",
      edit: { title: "Группа", path: "/views/dicts/DictClientGrp/ClientGrpEdit" },
      list: { title: "Группы предприятий", path: "/views/dicts/ClientGrpDict" }
    },
    {
      id: "paysys",
      remoteId: "DictPaySys",
      routePath: "/dicts/paysys/paysys",
      edit: { title: "Платежная система", path: "/views/dicts/DictPaySys/PaySysEdit" },
      list: { title: "Платежные системы", path: "/views/dicts/PaySysDict" }
    },
    {
      id: "paysyscardtype",
      remoteId: "DictPaySysCardType",
      routePath: "/dicts/paysys/cardtype",
      edit: { title: "Тип платежной карты", path: "/views/dicts/DictPaySysCardType/PaySysCardTypeEdit" },
      list: { title: "Типы платежных карт", path: "/views/dicts/PaySysCardTypeDict" }
    },
    {
      id: "terminal",
      remoteId: "DictTerminal",
      routePath: "/dicts/terminal",
      edit: { title: "Теримнал", path: "/views/dicts/DictTerminal/TerminalEdit" },
      list: { title: "Терминалы", path: "/views/dicts/TerminalDict" }
    },
    {
      id: "tst",
      remoteId: "DictTst",
      routePath: "/dicts/tst",
      edit: { title: "Торгово-сервиснвя точкв", path: "/views/dicts/DictTst/TstEdit" },
      list: { title: "Торгово-сервисные точки", path: "/views/dicts/TstDict" }
    },
    {
      id: "tx",
      remoteId: "DictTx",
      routePath: "/dicts/tx",
      edit: { title: "Транзакция", path: "/views/dicts/DictTx/TxEdit" },
      list: { title: "Транзакции", path: "/views/dicts/TxDict" }
    },
    {
      id: "dataloadlog",
      remoteId: "DictDataLoadLog",
      routePath: "/dicts/dataloadlog",
      edit: { title: "История загрузки", path: "/views/dicts/DictDataLoadLog/DataLoadLogEdit" },
      list: { title: "Истории загрузки", path: "/views/dicts/DataLoadLogDict" },
      access:
        [{role:"teacher", rubricator:false,create: false, read:false, edit:false, print:true},
          {role:"schadmin", rubricator:true,create: false, read:true, edit:false, print:true}]
    }

  ]
};

export default configData;
