import React, { Component } from 'react';
import {ApolloClient} from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client' //import { HttpLink } from 'apollo-link-http'
import gql from "graphql-tag";
import { Message } from "shineout";
import {CFG_KEY_SESSION, config} from "../Config";
//import client from '../remote/apollo';
//import { useApolloClient } from '@apollo/react-hooks';

const defaultApolloOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }

const apolloClient = new ApolloClient({
  link: createUploadLink({
    uri: '/query',
    credentials: 'include',
  }),
  cache: new InMemoryCache(),
  defaultOptions: defaultApolloOptions,
});

/*const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: '/query',
  }),
  cache: new InMemoryCache(),
  defaultOptions: defaultApolloOptions,
});*/

export const GET_DICT_DATA = gql`
  query ProcessDictAction($object: String!,$action: String!,$params: String!) {
    ProcessDictAction(object: $object,action: $action,params: $params) {
      status
      message
      body	
    }
  }
`;


export const UPLOAD_DICT_DATA = gql`
    mutation singleUpload($object: String!,$action: String!,$params: String!,$file: Upload!) {
      singleUpload(object: $object,action: $action,params: $params, file: $file) {
        status
        message
        body	
      }
    }
`;


export const apolloClientFetch = (appobjId, actionname, paramSet) => {
 return apolloClient.query({
            query: GET_DICT_DATA,
            variables: { object: appobjId, action: actionname,
		params: JSON.stringify(paramSet)
		},
          })
}

/*export const apolloClientUpload = (appobjId, actionname, paramSet, files) => {
 return apolloClient.mutate({
            nutation: UPLOAD_DICT_DATA,
            variables: { object: appobjId, action: actionname,
		params: JSON.stringify(paramSet),
		file: files
		}
          })
}*/

export const apolloClientUpload = (appobjId, actionname, paramSet, files) => {
 return apolloClient.query({
            query: UPLOAD_DICT_DATA,
            variables: { object: appobjId, action: actionname,
		params: JSON.stringify(paramSet),
		file: files
		}
          })
}


export const apolloFetcher = (appobjId, actionname, paramSet, callback, noShowError) => {
//        	console.log(paramSet)
//	alert(appobjId+ ": "+actionname)
  const user = config.getPersistValue(CFG_KEY_SESSION)
  paramSet["option"] = paramSet["option"] ? paramSet["option"] : {}
  if (!paramSet["option"]["user_id"] && user)
    paramSet["option"]["user_id"] = user.id
	apolloClientFetch (appobjId, actionname, paramSet).then(r => {
    console.log("  ---- apollo ----")
	  console.log(appobjId + " " + actionname)
    console.log(paramSet)
	  console.log(r)
    console.log("  ----  ")
//	if (r.error !)

   if (r.data.ProcessDictAction == null && r.errors[0].message)
     r.data.ProcessDictAction = {status: "-1", message: r.errors[0].message}

//   if (r.data.ProcessDictAction.status != "0")
//	alert(r.data.ProcessDictAction.message)


	if (r.data.ProcessDictAction.status == "0")
		;//Message.success("Операция успешно выполнена", 10);
	else  if (noShowError==undefined)
		Message.error("Ошибка -" + r.data.ProcessDictAction.message + ": " + appobjId + ", " + actionname ,10);

  callback(r.data.ProcessDictAction.status == "0", r.data.ProcessDictAction.message, JSON.parse(r.data.ProcessDictAction.body != undefined &&
		r.data.ProcessDictAction.body != "" ? r.data.ProcessDictAction.body : "[]"))

});
}

export const apolloUpload = (appobjId, actionname, paramSet, file, callback) => {
	apolloClientUpload (appobjId, actionname, paramSet, file).then(r => {

	console.log(r)
   if (r.data == null)
     r.data={}

   if (r.data.singleUpload == null && r.errors[0].message)
     r.data.singleUpload = {status: "-1", message: r.errors[0].message}


	if (r.data.singleUpload.status == "0")
		;//Message.success("Операция успешно выполнена", 10);
	else
		Message.error("Ошибка -" + r.data.singleUpload.message + ": " + appobjId + ", " + actionname ,10);

  callback(r.data.singleUpload.status == "0", r.data.singleUpload.message, JSON.parse(r.data.singleUpload.body != undefined &&
		r.data.singleUpload.body != "" ? r.data.singleUpload.body : "[]"))

//	console.log(r)

});
}




export default apolloClient;
