import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {config,CFG_KEY_SESSION} from '../../Config';

function PrivateRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>  config.getPersistValue(CFG_KEY_SESSION) != null//authed === true
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}/>

  )
}

export default PrivateRoute;